/*
@contract search-combobox {
  results-list-font-size: FontSize
  input-padding: Padding
  list-wrap-padding: Padding
  list-item-padding: Padding
  list-item-wrap-padding: Padding
  results-header {
    border-top: BorderTop
    padding: Padding
    padding-first: Padding
  }
  results-max-height: MaxHeight
  search-button-font-size: FontSize
  button-background-color: BackgroundColor
  button-background-color-hover: BackgroundColor
  list-item-highlighted-color: Color
  back-button-color: Color
  input-wrap-background: BackgroundColor
  input-wrap-border: Border
  icon-font-size: FontSize
  icon-min-width: MinWidth
}
*/

.root {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: var(--search-combobox-root-border-radius);
  border-width: 1px;
  border-color: var(--search-combobox-root-border-color);
  border-style: var(--search-combobox-root-border-style);
  outline-style: solid;
  outline-color: transparent;
  background: var(--color-white);
  padding: var(--search-combobox-root-padding);
}

.disabled {
  filter: grayscale(40%) brightness(85%);
  cursor: wait;
}

.searchContainer {
  text-align: center;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: row;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  flex-wrap: wrap;
  border-radius: var(--search-combobox-header-border-radius);

  ::-webkit-scrollbar {
    appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-2x);
    background-color: rgba(0 0 0 / 50%);
    box-shadow: 0 0 1px rgba(255 255 255 / 50%);
  }
}

.searchContainerVisible {
  border-radius: var(--search-combobox-header-visible-border-radius);
}

.input {
  border-width: 0;
  background: none;
  margin: 0;
  flex: 1 1 0;
  width: 100%;
  appearance: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: var(--search-combobox-input-padding);
  padding-right: var(--space-2x);
  outline: none;
  color: var(--color-gray-500);
}

.disabled .input {
  cursor: wait;
}

.input::placeholder {
  color: var(--color-gray-400);
}

.input[type='search']::-webkit-search-decoration,
.input[type='search']::-webkit-search-cancel-button,
.input[type='search']::-webkit-search-results-button,
.input[type='search']::-webkit-search-results-decoration {
  appearance: none;
}

.searchButton {
  text-transform: none;
  cursor: pointer;
  touch-action: manipulation;
  background-image: none;
  margin: var(--space-2x);
  border: 0;
  border-radius: var(--search-combobox-button-border-radius);
  background-color: var(--search-combobox-button-background-color);
  padding: var(--space-2x) var(--space-5x);
  font-size: var(--search-combobox-search-button-font-size);
  font-weight: bold;
  color: var(--color-white);
  z-index: var(--z-index-0);
  &:hover {
    background-color: var(--button-background-color-hover);
  }

  &:active {
    background-color: var(--search-combobox-button-background-color-active);
  }
}

.disabled .searchButton {
  pointer-events: none;
  cursor: wait;
}

.listWrapper {
  visibility: hidden;
  position: absolute;
  list-style: none;
  right: auto;
  bottom: auto;
  left: var(--search-combobox-list-wrap-left);
  display: inline-block;
  width: 100%;
  transform: none;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0.25rem;
  border-width: 1px;
  background-color: var(--color-white);
  padding: var(--search-combobox-list-wrap-padding);
  font-size: var(--search-combobox-results-list-font-size);
  color: var(--color-gray-500);
  max-height: 274px;
  z-index: var(--z-index-2x);
  border-left: var(--search-combobox-list-wrap-horizontal-border);
  border-right: var(--search-combobox-list-wrap-horizontal-border);
  border-top: var(--search-combobox-results-list-vertical-border);
  border-bottom: var(--search-combobox-results-list-vertical-border);
  outline: var(--search-combobox-list-wrap-outline);
  box-shadow: var(--search-combobox-list-wrap-box-shadow);

  @media (--screen-md) {
    max-height: 75vh;
    position: relative;
    min-width: 350px;
  }
}

.listWrapperVisible {
  visibility: visible;
  border-radius: var(--search-combobox-list-wrap-visible-border-radius);
}

.clearSearchTextButton,
.searchIcon {
  z-index: var(--search-combobox-close-button-z-index);
  font-weight: bold;
  font-size: var(--font-size-md);
  color: var(--search-combobox-close-button-color);
  display: flex;
  align-items: center;
  padding: 0 var(--space-3x) 0 0;
}

.searchIcon {
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.clearSearchTextButton:hover {
  color: var(--search-combobox-close-button-color-hover);
}

.clearSearchTextButton:active {
  color: var(--search-combobox-close-button-color-active);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: var(--color-white);
  font-size: var(--font-size-md);
  color: var(--color-gray-500);
}

.listHeader {
  padding: var(--search-combobox-results-header-padding);
  font-weight: var(--font-weight-semibold);
  line-height: 1.1;
  font-feature-settings: 'ss01';
  text-align: left;
  font-size: var(--font-size-sm);
  text-transform: capitalize;
  color: var(--color-gray-500);
  border-top: 1px solid var(--color-gray-200);
  margin-top: var(--space-3x);
}

.listHeader:first-child {
  margin-top: 0;
  padding: var(--search-combobox-results-header-padding-first);
}

.listItemWrap {
  padding: var(--search-combobox-list-item-wrap-padding);
}

.listItem,
.propertiesListItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: var(--border-radius-2x);
  padding: var(--search-combobox-list-item-padding);
  text-align: left;
  color: var(--color-gray-500);
  display: flex;
  align-items: center;
  gap: var(--space-2x);
  cursor: pointer;

  a {
    display: block;
    color: var(--color-gray-500);
    text-decoration: none;
  }
}

.propertiesListItem {
  align-items: flex-start;
}

@media (hover: hover) {
  .listItem:hover {
    background: var(--search-combobox-list-item-hover-color);
  }
}

.listItemTextHighlight {
  font-weight: bold;
  color: var(--color-black);
}

.listItemHighlighted {
  background: var(--search-combobox-list-item-highlighted-color);
}

.icon {
  font-size: var(--search-combobox-icon-font-size);
  min-width: var(--search-combobox-icon-min-width);
  color: var(--search-combobox-list-item-icon-color);
}

.inputWrap {
  display: flex;
  width: 100%;
}

.isNotModal {
  display: contents;
}

.isModal {
  --height: 70px;

  .searchButton {
    display: none;
  }

  .searchContainer {
    background-color: var(--search-combobox-header-search-input-background);
  }

  .backButton {
    color: var(--search-combobox-back-button-color);
    padding-left: 0;
    padding-right: var(--space-4x);
  }

  .listHeader:first-child {
    border: 0;
  }

  .listItem.currentLocationListItem {
    padding: 0;
  }

  &.isModalOpen {
    position: fixed;
    z-index: var(--z-index-4x);
    inset: 0;
    width: 100%;
    height: 100%;

    .clearSearchTextButton,
    .searchIcon {
      display: none;
    }

    .listWrapper {
      border-radius: 0;
      height: 100%;
      flex: 1;
      top: var(--height);
      max-height: unset;
      padding-bottom: 60vh;
    }

    .inputWrap {
      position: relative;
      background: var(--search-combobox-input-wrap-background);
      border-bottom: var(--search-combobox-input-wrap-border);
      display: flex;
      align-items: center;
      width: 100%;
      height: var(--height);
      padding: 0 var(--space-4x);
    }

    .input {
      max-width: unset;
      height: var(--space-10x);
      padding-left: var(--space-3x);
      border-radius: var(--border-radius-2x) 0 0 var(--border-radius-2x);
      margin: 0;
    }

    .searchButton {
      height: var(--space-10x);
      width: var(--space-10x);
      padding: 0;
      margin: 0;
      border-radius: 0 var(--border-radius-2x) var(--border-radius-2x) 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .root {
      display: flex;
      flex-direction: column;
      height: 100%;
      isolation: isolate;
    }

    .searchContainer {
      flex-direction: column;
      height: 100%;
    }
  }
}

.modalSearchLoadingIndicator {
  padding: 50px 0;
  width: fit-content;
  margin: auto;
}

.propertyListItemInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.propertiesListItemType {
  font-size: var(--font-size-sm);
  text-transform: capitalize;
}

.fullWidth {
  width: 100%;
}
